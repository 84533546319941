<template>
  <div class="notify">
    <div class="overlay" @click="$emit('closeNotify')"></div>
    <div class="notify__content">
      <div class="notify__title">Обновления "Мой AZ-MOST"</div>
      <div
        class="notify__list"
        v-if="notify.length && user.specialities.length && user.diseases.length"
      >
        <div
          class="notify__item"
          v-for="item in notify.filter((el) => typeof el.object === 'object')"
          :data-id="item.id"
          :key="item.id"
          :class="{ notify__item_view: item.seen }"
        >
          <div class="notify-item__image-container d-none" v-if="false">
            <img class="notify-item__image" :src="item.announce" />
          </div>
          <div
            class="notify-item__text"
            @click="toNotify(getItemInfo(item).link)"
            v-html="getItemInfo(item).text"
          ></div>
          <div class="notify-item__remove" @click="removeNotify(item.id)">
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 3.5L12.5 12.5"
                stroke="#8B8E8E"
                stroke-linecap="square"
              />
              <path
                d="M12.5 3.5L3.5 12.5"
                stroke="#8B8E8E"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        class="notify__empty-title"
        v-if="!user.specialities.length && !user.diseases.length"
      >
        Сюда будут приходить уведомления при публикации новых материалов, если
        вы выбрали интересующие вас терапевтические области и заболевания в
        <router-link style="color: #830051" :to="{name: 'MaterialsSettings'}">"Мой AZ-MOST"</router-link>
      </div>
      <div
        class="button button_gray button_small mx-4 mb-4 mt-5"
        @click="clearAll"
        v-if="user.specialities.length && user.diseases.length && notify.length"
      >
        Очистить все
      </div>
      <div
        v-if="
          user.specialities.length && user.diseases.length && !notify.length
        "
        class="notify__empty-title"
      >
        Новых уведомлений нет
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from '@/main'
export default {
  name: "Notify",
  data: () => ({
    timer: null,
    visibleNotify: [],
  }),
  computed: {
    ...mapGetters(["notify", "user"]),
  },
  methods: {
    ...mapActions(["getNotify", "deleteNotify", "readNotify"]),
    removeNotify(id) {
      this.deleteNotify({ user_id: this.user.id, notify_ids: [id] });
    },
    viewNotify(ids) {
      this.readNotify({ user_id: this.user.id, notify_ids: ids });
    },
    clearAll() {
      this.deleteNotify({
        user_id: this.user.id,
        notify_ids: this.notify.map((el) => el.id),
      });
    },
    toNotify(to) {
      bus.$emit('scrollLock', false)
      if (typeof to !== "object") {
        if (to.includes("http")) {
          window.open(to, "_blank");
        }
      } else {
        this.$router.push(to).catch(() => {});
      }
    },
    notifyVisible() {
      clearTimeout(this.timer);
      this.timer = null;
      let list = this.$el.querySelector(".notify__list");
      this.$el
        .querySelectorAll(".notify__item:not(.notify__item_view)")
        .forEach((item) => {
          const id = item.getAttribute("data-id");
          let docViewTop = list.scrollTop;
          let docViewBottom = docViewTop + list.clientHeight;

          let elemTop = item.offsetTop - list.offsetTop;
          let elemBottom = elemTop + item.clientHeight;

          if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            if (!this.visibleNotify.includes(id)) {
              this.visibleNotify.push(id);
            }
          }
        });
      if (!this.timer) {
        this.timer = setTimeout(() => {
          if (this.visibleNotify.length) {
            this.viewNotify(this.visibleNotify);
          }
          this.visibleNotify = [];
        }, 3000);
      }
    },
    getItemInfo(item) {
      switch (item.object.object_type) {
        case "disease_content":
          if(item.type === "UPDATE") {
            return {
            ...item.object,
            name: item.object.title,
            link: {
              name: "Disease",
              params: {
                slug: item.object.nosology_slug,
                disease: item.extra_data.parent.slug,
                block_id: item.object.id
              },
            },
            text: `Обновлен раздел ${item.object.title} в заболевании <span
              class="notify-item__text_highlighted"
              >${item.extra_data.parent.title}</span>`,
          };
          }

           if(item.type === "NEW") {
            return {
            ...item.object,
            name: item.object.title,
            link: {
              name: "Disease",
              params: {
                slug: item.extra_data.parent.slug,
                disease: item.extra_data.parent.slug,
                block_id: item.object.id
              },
            },
            text: `Добавлен новый раздел ${item.object.title} в заболевании <span
              class="notify-item__text_highlighted"
              >${item.extra_data.parent.title}</span>`,
          };
          }
          
        case "nosology":
          return {
            link: {
              name: "NosologyDetail",
              params: { slug: item.object.slug },
            },
          };
        case "drug":
          return {
            ...item.object,
            link: {
              name: "MedicationDetail",
              params: { medication: item.object.slug },
            },
            text: `Добавлен новый препарат в терапевтической области <span
              class="notify-item__text_highlighted"
              >${
                item.object.nosology
                  ? item.object.nosology.map((el) => el.title).join(", ")
                  : ""
              }</span
            > ${item.object.name}`,
          };
        case "event":
          return {
            ...item.object,
            link: item.object.external ? item.object.external_link : { name: "EventDetail", params: { id: item.object.id } },
            text: `Добавлено новое мероприятие в терапевтической области <span
              class="notify-item__text_highlighted"
              >${
                item.object.nosology
                  ? item.object.nosology.map((el) => el.title).join(", ")
                  : ""
              }</span
            > ${item.object.name}`,
          };
        case "text_material":
          return {
            ...item.object,
            link: { name: "TextDetail", params: { slug: item.object.slug } },
            text: `Добавлена новая публикация в терапевтической области <span
              class="notify-item__text_highlighted"
              >${
                item.object.nosology
                  ? item.object.nosology.map((el) => el.title).join(", ")
                  : ""
              }</span
            > ${item.object.name}`,
          };
        case "video_material":
          return {
            ...item.object,
            link: { name: "VideoDetail", params: { slug: item.object.slug } },
            text: `Добавлен новый видеоматериал в терапевтической области <span
              class="notify-item__text_highlighted"
              >${
                item.object.nosology
                  ? item.object.nosology.map((el) => el.title).join(", ")
                  : ""
              }</span
            > ${item.object.name}`,
          };
        case "podcast":
          return {
            ...item.object,
            link: {
              name: "PodcastDetail",
              params: { slug: item.object.slug },
            },
            text: `Добавлен новый подкаст в терапевтической области <span
              class="notify-item__text_highlighted"
              >${
                item.object.nosology
                  ? item.object.nosology.map((el) => el.title).join(", ")
                  : ""
              }</span
            > ${item.object.name}`,
          };
        default:
          return "#";
      }

      // case "disease_update":
      //   return "Новый раздел Патогенез в заболевании";
      // case "disease_create":
      //   return "Обновлен раздел Патогенез в заболевании";
    },
  },
  async mounted() {
    if (this.notify.length) {
      this.notifyVisible();
      this.$el
        .querySelector(".notify__list")
        .addEventListener("scroll", this.notifyVisible, false);
    }
  },
  beforeDestroy() {
    if (this.notify.length) {
      this.$el
        .querySelector(".notify__list")
        .removeEventListener("scroll", this.notifyVisible, false);
    }
  },
};
</script>

<style lang="scss" scoped>
.notify {
  position: absolute;
  top: calc(100% + 20px);
  right: -30px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  background-color: #fff;
  width: 384px;

  @media screen and (max-width: 767px) {
    width: 90vw;
    right: -50px;
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &::before {
    content: "";
    position: absolute;
    right: 30px;
    bottom: 100%;
    transform: translate(-50%);
    border: 10px solid transparent;
    border-bottom: 10px solid #830051;

    @media screen and (max-width: 767px) {
      right: 50px;
    }
  }
  &__title {
    padding: 16px 24px 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;
    background: #830051;
    border-radius: 8px 8px 0 0;
  }

  &__empty-title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
    text-align: center;
    padding: 16px;
  }

  &__list {
    padding: 16px;
    padding-right: 8px;
    max-height: 400px;
    overflow-y: auto;
    -ms-overflow-style: scrollbar;
    scrollbar-color: #d2d2d2 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d2d2d2;
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: #ebefee;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #ebefee;
      border: 3px solid #fff;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  }

  &__item {
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 8px;
    transition: 0.3s;
    cursor: pointer;

    &_view {
      & .notify-item__text {
        opacity: 0.6;
        &::before {
          display: none;
        }
      }
    }

    &:hover {
      background: #ebefee;
    }
  }

  &-item {
    &__text {
      padding-left: 14px;
      position: relative;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      padding-right: 24px;

      &_highlighted {
        color: #830051;
      }

      &::before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: #830051;
        border-radius: 50%;
        display: block;
        content: "";
      }
    }

    &__remove {
      position: absolute;
      top: 16px;
      right: 8px;
      width: 16px;
      height: 16px;
      display: block;
      cursor: pointer;
    }

    &__image-container {
      width: 64px;
      height: 64px;
      border-radius: 8px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 15px;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  cursor: default;
}
</style>

<style>
.notify-item__text_highlighted {
  color: #830051;
}
</style>